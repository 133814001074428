import w from './images/sparkling.jpeg';
import v from './images/stillwater.jpg';
// import c from './images/cola.jpeg';
// import p from './images/pepsi.jpeg';
// import u from './images/7up.jpeg';
const Cdrink = {
    Colddrink:[
       {
           id:  '1',
           name: 'VOSS STILL WATER',
           price: '10.50',
           image: v,
           details: 'all prices include 5% vat'
       },
       {
           id:  '2',
           name: 'SPARKLING WATER',
           price: '7.15',
           image: w,
           details: 'all prices include 5% vat'
       },
    //    {
    //        id:  '3',
    //        name: 'COCA COLA',
    //        price: '5.25',
    //        image: c,
    //        details: 'all prices include 5% vat'
    //    },
    //    {
    //        id:  '4',
    //        name: '7Up',
    //        price: '5.25',
    //        image: u,
    //        details: 'all prices include 5% vat'
    //    },
    //    {
    //        id:  '5',
    //        name: 'PEPSI',
    //        price: '5.25',
    //        image: p,
    //        details: 'all prices include 5% vat'
    //    },
     
   ]
}

export default Cdrink;