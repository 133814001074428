import React from 'react';
import Breakfast from './Breakfast';


const Home = () => {
    

  return (
      <div className='home'>
        <Breakfast />
    </div>
  ) 
}

export default Home;